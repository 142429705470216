// State manager (Zustand)
//
// NOTE:  Functions that require more complex logic are handled in storeOps.tsx

import { create } from 'zustand'
import { BaseMap } from './BaseMapPanel';
import { IBaseMapLayer } from './Layers/LayersPanel';
//import { devtools } from "zustand/middleware";
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { ILayer, MapboxLayerType } from './Layers/LayerInterfaces';
import { IProject, IProjectAoi, IProjectScenario, IProjectUserSettings, ProjectUIMode, IOrgProjects, IProjectParcelAttribute, IProjectUserSettingsUserValue } from './Projects/ProjectInterfaces';
import { IBundle, IBundleListItem, INRR, INRRImpact, BundleUIMode, INRRImpactLink } from './Bundles/BundleInterfaces';
import { IAoi, IAoiListItem, AoiUIMode, IAoiProperties, IAoiNote, IAoiGroupProperties } from './Aois/AoiInterfaces';
import { IUserProfile } from './UserProfile';
import { MapScaleUnits } from './AppSettingsDialog';
import { ILegendEntry } from "./Legend/LegendInterfaces";
import { IMapView } from './Map/Map';
import { IToastNotification } from './ToastNotifications';
import { IHbvScenario } from './HBV/HbvInterfaces';
import { DrawerMenuItemType } from './DrawerMenu/DrawerMenu';
import { IUserInfo } from './Account/UserOps';
import { IHbvChartColor } from "./HBV/HbvInterfaces";
import { IParcel, IParcelSearchClause, IParcelSearchExpression, TParcelSearchExpressionOperator, TParcelSearchAreaFilter, TSortOrder } from './Parcels/ParcelInterfaces';
import { API_DEBUG_MAX_ENTRIES, IAPIDebugData } from './APIDebugInfo';
import { IIdentifyData } from './Identify/IdentifyInterfaces';
import { ILayerLibraryGroup } from './LayerLibrary/LayerLibraryInterfaces';
import { IProjectSharingOrg } from './Projects/ProjectShare';
import { PARCEL_SEARCH_DEFAULT_MAX_RESULTS, TParcelSearchMaxResults, TStateAbbreviation } from './Parcels/ParcelSearch';



//-------------------------------------------------------------------------------
// Zustand state store interface
//-------------------------------------------------------------------------------
export interface IStore
{
  // Project

  store_orgProjectsList: IOrgProjects[];
  store_setOrgProjectsList: (newList: IOrgProjects[]) => void;
  store_removeProjectFromList: (projectID: string) => void;
  store_projectListIsLoading: boolean;
  store_setProjectListIsLoading: (newValue: boolean) => void;
  store_projectUIMode: ProjectUIMode;
  store_setProjectUIMode: (newValue: ProjectUIMode) => void;

  store_project: IProject | null;
  store_setProject: (newProject: IProject | null) => void;
  store_projectIsLoading: boolean;
  store_setProjectIsLoading: (newValue: boolean) => void;
  store_projectSurpressSaving: boolean;
  //store_setProjectClientSideLoadDate: (newValue: Date) => void;
  store_setProjectServerSideLoadDate: (newValue: string | undefined) => void;
  store_setProjectIsDirty: (newSaveState: boolean) => void;
  store_setProjectActiveBundle: (newBundleID: number) => void;
  store_setProjectName: (newName: string) => void;
  store_setProjectOrg: (newOrgID: number | undefined) => void;
  store_projectRename: (projectID: string, newName: string) => void;
  store_setProjectUserSettings: (newValue: IProjectUserSettings) => void;
  store_setProjectMapView: (newValue: IMapView) => void;
  store_setProjectLastActiveAoi: (newValue: number | null) => void;
  store_setProject_IdentifyShowEmptyValues: (newValue: boolean) => void;
  store_setProject_IdentifyShowOnlyParcelUserAttribs: (newValue: boolean) => void;
  store_setProject_UserValues: (newValue: IProjectUserSettingsUserValue[]) => void;
  store_projectAddAoi: (newAoi: IProjectAoi) => void;
  store_projectRemoveAoi: (aoi_id: number) => void;
  store_projectRenameAoi: (aoi_id: number, newName: string) => void;
  store_projectUpdateAoi: (newValue: IProjectAoi) => void;
  store_projectAddScenario: (newItem: IProjectScenario) => void;
  store_projectUpdateScenario: (newValue: IProjectScenario) => void;
  store_projectRemoveScenario: (scenario_id: number) => void;

  store_orgUsersLoading: boolean;
  store_setOrgUsersLoading: (newValue: boolean) => void;
  store_projectSharingOrgs: IProjectSharingOrg[];
  store_setProjectSharingOrgs: (newValue: IProjectSharingOrg[]) => void;
  
  store_setProjectHbvScenarioSelected: (hbv_scenario_id: number, newValue: boolean) => void;
  store_addProjectHbvScenario: (newScenario: IProjectScenario) => void;
  store_removeProjectHbvScenario: (hbv_scenario_id: number) => void;
  store_setProjectHbvScenarioName: (hbv_scenario_id: number, newName: string) => void;
  store_setProjectHbvScenarioInfo: (hbv_scenario_id: number, newDollars: number, newLastRunDate: string) => void;

  store_setProjectParcelAttribList: (newAttribList: IProjectParcelAttribute[]) => void;
  store_addProjectParcelAttrib: (newValue: IProjectParcelAttribute) => void;
  store_removeProjectParcelAttrib: (attribute_name: string) => void;

  // AOI

  store_aoiList: IAoiListItem[];
  store_setAoiList: (newList: IAoiListItem[]) => void;
  store_aoiListIsLoading: boolean;
  store_setAoiListIsLoading: (newValue: boolean) => void;
  store_aoiUIMode: AoiUIMode;
  store_setAoiUIMode: (newValue: AoiUIMode) => void;
  store_aoi: IAoi | null;
  store_setAoi: (newAoi: IAoi | null) => void;
  store_aoiIsLoading: boolean;
  store_setAoiIsLoading: (newValue: boolean) => void;
  store_aoiIsSaving: boolean;
  store_setAoiIsSaving: (newValue: boolean) => void;
  store_aoiIsDeleting: boolean;
  store_setAoiIsDeleting: (newValue: boolean) => void;
  store_setAoiName: (newValue: string) => void;
  store_setAoiIsDirty: (newValue: boolean) => void;
  store_setAoiUpdatedGeom: (newValue: any) => void;
  store_setAoiLastSavedGeom: (newValue: any) => void;
  store_setAoiPolygonCount: (newValue: number) => void;
  store_setAoiAcres: (newValue: number) => void;
  store_aoiFileIsUploading: boolean;
  store_setAoiFileIsUploading: (newValue: boolean) => void;
  store_aoiIsExporting: boolean;
  store_setAoiIsExporting: (newValue: boolean) => void;
  store_setAoiNote: (newValue: string) => void;

  // AOI group properties (and AOI attributes)

  store_portfolioMapAois: IAoi[];
  store_setPortfolioMapAois: (newValue: IAoi[]) => void;
  store_aoiGroupProperties: IAoiGroupProperties | undefined;
  store_setAoiGroupProperties: (newValue: IAoiGroupProperties | undefined) => void;
  store_editAoiGroupProperties: boolean;
  store_setEditAoiGroupProperties: (newValue: boolean) => void;
  store_aoiGroupPropertiesIsSaving: boolean;
  store_setAoiGroupPropertiesIsSaving: (newValue: boolean) => void;

  // AOI attributes

  store_editAoiAttributes: boolean;
  store_setEditAoiAttributes: (newValue: boolean) => void;

  // Bundles | NRRs | Impacts

  store_bundleList: IBundleListItem[];
  store_setBundleList: (newList: IBundleListItem[]) => void;
  store_bundleListIsLoading: boolean;
  store_setBundleListIsLoading: (newValue: boolean) => void;

  store_bundle: IBundle | null;
  store_setBundle: (newBundle: IBundle | null) => void;
  store_bundleUIMode: BundleUIMode;
  store_setBundleUIMode: (newValue: BundleUIMode) => void;
  store_bundleIsLoading: boolean;
  store_setBundleIsLoading: (newValue: boolean) => void;

  store_nrrs: INRR[];
  store_setNrrs: (newList: INRR[]) => void;
  store_setNrrExpanded: (nrr_id: number, newValue: boolean) => void;
  store_nrrImpacts: INRRImpact[];
  store_setNrrImpacts: (newList: INRRImpact[]) => void;
  store_nrrImpactLinks: INRRImpactLink[];
  store_setNrrImpactLinks: (newList: INRRImpactLink[]) => void;
  store_getNrrImpactLink: (nrr_id: number, impact_id: number) => INRRImpactLink | undefined;

  store_editUserValues: boolean;
  store_setEditUserValues: (newValue: boolean) => void;
  store_editUserValuesNrrID: number | undefined;
  store_setEditUserValuesNrrID: (newValue: number | undefined) => void;
  store_editUserValuesImpactID: number | undefined;
  store_setEditUserValuesImpactID: (newValue: number | undefined) => void;

  // HBV

  store_hbvScenario: IHbvScenario | null;
  store_setHbvScenario: (newValue: IHbvScenario | null) => void;
  store_setHbvScenarioID: (newValue: number | null) => void;
  store_setHbvScenarioName: (newValue: string) => void;
  store_hbvIsRunning: boolean;
  store_setHbvIsRunning: (newValue: boolean) => void;
  store_hbvRunInstanceID: number | null;
  store_setHbvRunInstanceID: (newValue: number | null) => void;
  store_hbvScenarioIsLoading: boolean;
  store_setHbvScenarioIsLoading: (newValue: boolean) => void;
  store_hbvScenarioCompareIsLoading: boolean;
  store_setHbvScenarioCompareIsLoading: (newValue: boolean) => void;
  store_hbvComparedScenarios: IHbvScenario[];
  store_setHbvComparedScenarios: (newValue: IHbvScenario[]) => void;
  store_hbvScenarioIsSaving: boolean;
  store_setHbvScenarioIsSaving: (newValue: boolean) => void;
  store_hbvScenarioRefreshIsLoading: boolean;
  store_setHbvScenarioRefreshIsLoading: (newValue: boolean) => void;

  store_chartColors: IHbvChartColor[];
  store_setChartColors: (newValue: IHbvChartColor[]) => void;
  store_addChartColor: (newName: string, newColor: string) => void;
  
  // Map

  store_map: mapboxgl.Map | null;
  store_setMap: (newValue: mapboxgl.Map | null) => void;
  store_mapSearchControl: MapboxGeocoder | null;
  store_setMapSearchControl: (newValue: MapboxGeocoder | null) => void;
  store_mapDrawControl: MapboxDraw | null;
  store_setMapDrawControl: (newValue: MapboxDraw | null) => void;
  store_mapScaleControl: mapboxgl.ScaleControl | null;
  store_setMapScaleControl: (newValue: mapboxgl.ScaleControl | null) => void;
  store_mapCompassControl: mapboxgl.NavigationControl | null;
  store_setMapCompassControl: (newValue: mapboxgl.NavigationControl | null) => void;
  store_mapGeolocateControl: mapboxgl.GeolocateControl | null;
  store_setMapGeolocateControl: (newValue: mapboxgl.GeolocateControl | null) => void;
  store_baseMap: BaseMap;
  store_setBaseMap: (newValue: BaseMap) => void;
  store_threeDTerrain: boolean;
  store_setThreeDTerrain: (newValue: boolean) => void;

  // Layers

  store_layers: ILayer[];
  store_setLayers: (newValue: ILayer[]) => void;
  store_addLayer: (newLayer: ILayer) => void;
  store_addOrUpdateLayer: (updatedLayer: ILayer) => void;
  store_removeLayer: (id: number) => void;
  store_setLayerEnabled: (id: number, newState: boolean) => void;
  store_setLayerOpacity: (id: number, newValue: number) => void;
  store_setLayerExpanded: (id: number, newValue: boolean) => void;
  store_setLayerLegend: (id: number, newValue: ILegendEntry[]) => void;
  store_setLayerType: (id: number, newValue: MapboxLayerType) => void;
  store_setLayerStyle: (id: number, newPaint: any) => void;
  store_setLegendShowAll: (id: number, newValue: boolean) => void;
  store_layersForLegendPanel: () => ILayer[];
  store_activeInfoLayer: ILayer | null;
  store_setActiveInfoLayer: (newValue: ILayer | null) => void;

  // Layer library

  store_layerLibraryGroups: ILayerLibraryGroup[];
  store_setLayerLibraryGroups: (newValue: ILayerLibraryGroup[]) => void;
  store_layerLibraryActive: boolean;
  store_setLayerLibraryActive: (newValue: boolean) => void;
  store_addLayerLibraryGroup: (newGroup: ILayerLibraryGroup) => void;
  store_removeLayerLibraryGroup: (groupID: number) => void;
  store_setLayerLibraryGroupName: (groupID: number, newGroupName: string) => void;
  store_creatingNewLayer: boolean;
  store_setCreatingNewLayer: (newValue: boolean) => void;
  store_deletingLayer: boolean;
  store_setDeletingLayer: (newValue: boolean) => void;

  // Layer editor

  store_editLayer: ILayer | undefined;
  store_setEditLayer: (newValue: ILayer | undefined) => void;
  store_editLayerFromLayerLibrary: boolean;
  store_setEditLayerFromLayerLibrary: (newValue: boolean) => void;
  store_uploadingLegendImage: boolean,
  store_setUploadingLegendImage: (newValue: boolean) => void;
  store_uploadingMapImage: boolean,
  store_setUploadingMapImage: (newValue: boolean) => void;
 
  // Base map bundle layers

  store_baseMapLayer_roads: IBaseMapLayer;
  store_baseMapLayer_states: IBaseMapLayer;
  store_baseMapLayer_labels: IBaseMapLayer;
  store_baseMapLayer_water: IBaseMapLayer;
  store_setBaseMapLayer: (name: string, opacity: number, matchList: string[]) => void;
  store_activateBaseMapLayer: (name: string, newState: boolean) => void;

  // Parcels

  store_selectedParcels: IParcel[];
  store_setSelectedParcels: (newValue: IParcel[]) => void;
  store_addSelectedParcel: (newItem: IParcel) => void;
  store_removeSelectedParcel: (id: number) => void;
  store_selectedParcelsTotalAcres: number | undefined;
  store_setSelectedParcelsTotalAcres: (newValue: number | undefined) => void;
  store_parcelEditMode: boolean;
  store_setParcelEditMode: (newValue: boolean) => void;

  store_parcelExtraAttributeList: IProjectParcelAttribute[],
  store_setParcelExtraAttributeList: (newValue: IProjectParcelAttribute[]) => void,
  store_addParcelExtraAttribute: (attribute_name: string) => void,
  store_removeParcelExtraAttribute: (attribute_name: string) => void,

  // Parcel search

  store_parcelSearchExpression: IParcelSearchExpression;
  store_setParcelSearchExpression: (newValue: IParcelSearchExpression) => void;
  store_setParcelSearchExpressionOperator: (newValue: TParcelSearchExpressionOperator) => void;
  store_addParcelSearchClause: (newItem: IParcelSearchClause) => void;
  store_removeParcelSearchClause: (clause_id: number) => void;
  store_updateParcelSearchClause: (newClause: IParcelSearchClause) => void;
  store_parcelSearchIsRunning: boolean,
  store_setParcelSearchIsRunning: (newValue: boolean) => void,
  store_parcelSearchEnabled: boolean,
  store_setParcelSearchEnabled: (newValue: boolean) => void,
  store_parcelSearchRunInstanceID: number | undefined,
  store_setParcelSearchRunInstanceID: (newValue: number | undefined) => void,
  store_parcelSearchMaxResults: TParcelSearchMaxResults,
  store_setParcelSearchMaxResults: (newValue: TParcelSearchMaxResults) => void,
  store_parcelSearchDbSortAttribName: string | undefined,
  store_setParcelSearchDbSortAttribName: (newValue: string | undefined) => void,
  store_parcelSearchDbSortOrderDesc: boolean,
  store_setParcelSearchDbSortOrderDesc: (newValue: boolean) => void,
  store_parcelSearchStates: TStateAbbreviation[],
  store_setParcelSearchStates: (newValue: TStateAbbreviation[]) => void,
  store_parcelSearchAreaFilter: TParcelSearchAreaFilter,
  store_setParcelSearchAreaFilter: (newValue: TParcelSearchAreaFilter) => void,
  store_parcelSearchResultsViewerMode: boolean,
  store_setParcelSearchResultsViewerMode: (newValue: boolean) => void,
  store_parcelSearchAOIBufferMiles: string,
  store_setParcelSearchAOIBufferMiles: (newValue: string) => void,

  // Parcel table viewer

  store_parcelTableViewer: boolean,
  store_setParcelTableViewer: (newValue: boolean) => void,
  //store_parcelTableViewerVertScroll: number,
  //store_setParcelTableViewerVertScroll: (newValue: number) => void,
  store_parcelTableLocalSortAttribName: string | undefined,
  store_setParcelTableLocalSortAttribName: (newValue: string | undefined) => void,
  store_parcelTableLocalSortOrder: TSortOrder | undefined,
  store_setParcelTableLocalSortOrder: (newValue: TSortOrder | undefined) => void,
  store_parcelsTableCurrPage: number,
  store_setParcelsTableCurrPage: (newValue: number) => void,

  // Parcel attribute selector

  store_parcelAttribSelector: boolean,
  store_setParcelAttribSelector: (newValue: boolean) => void,

  // User account

  store_username: string;
  store_setUsername: (username: string) => void;
  store_idToken: string;
  store_setIdToken: (idToken: string) => void;
  store_accessToken: string;
  store_setAccessToken: (accessToken: string) => void;
  store_refreshToken: string;
  store_setRefreshToken: (refreshToken: string) => void;
  store_isLoggedIn: boolean;
  store_setIsLoggedIn: (isLoggedIn: boolean) => void;
  store_userInfo: IUserInfo | null;
  store_setUserInfo: (newValue: IUserInfo | null) => void;
  store_pwdResetRunningStep1: boolean;
  store_setPwdResetRunningStep1: (newValue: boolean) => void;
  store_pwdResetRunningStep2: boolean;
  store_setPwdResetRunningStep2: (newValue: boolean) => void;
  store_logInDate: Date | null;
  store_setLogInDate: (newValue: Date | null) => void;
  store_forcedLogout: boolean;
  store_setForcedLogout: (newValue: boolean) => void;

  // User profile (includes app settings)

  store_userProfile: IUserProfile | null;
  store_setUserProfile: (newValue: IUserProfile | null) => void;
  store_userProfileIsLoading: boolean;
  store_setUserProfileIsLoading: (newValue: boolean) => void;
  store_setUserProfileIsDirty: (newValue: boolean) => void;
  store_setUserProfileMapScale: (newValue: boolean) => void;
  store_setUserProfileMapScaleUnits: (newValue: MapScaleUnits) => void;
  store_setUserProfileMapCompass: (newValue: boolean) => void;
  store_setUserProfileMapGeolocate: (newValue: boolean) => void;
  store_setUserProfileActiveProjectID: (newProjectID: string | null) => void;
  store_setUserProfileTerrain3DExaggeration: (newValue: number) => void;
  store_setUserProfileAgreedToTermsOfService: (newValue: boolean) => void;

  // Permissions

  store_permCreateLayerLibraryGroup: boolean;
  store_setPermCreateLayerLibraryGroup: (newValue: boolean) => void;
  store_permEditMainLayer: boolean;
  store_setPermEditMainLayer: (newValue: boolean) => void;

  // Identify

  store_identify?: IIdentifyData;
  store_setIdentify: (newValue?: IIdentifyData) => void;
  store_setIdentifyIsLoading: (newValue: boolean) => void;
  store_lastIdentifySelectionID: number | undefined;
  store_setLastIdentifySelectionID: (id: number | undefined) => void;
  store_identifySettingsOpen: boolean;
  store_setIdentifySettingsOpen: (newValue: boolean) => void;

  // Drawer menu

  store_drawerOpen: boolean; 
  store_setDrawerOpen: (newValue: boolean) => void;
  store_activeDrawerMenuItem: DrawerMenuItemType;
  store_setActiveDrawerMenuItem: (newValue: DrawerMenuItemType) => void;

  // Misc

  store_toastNotification: IToastNotification | null;
  store_setToastNotification: (newValue: IToastNotification) => void;
  store_setToastNotificationOpen: (newValue: boolean) => void;
  store_forcedAppReload: boolean;
  store_setForcedAppReload: (newValue: boolean) => void;
  store_APIDebugMode: boolean;
  store_setAPIDebugMode: (newValue: boolean) => void;
  store_APIDebugData: IAPIDebugData[];
  store_setAPIDebugData: (newValue: IAPIDebugData[]) => void;
  store_addAPIDebugData: (newValue: IAPIDebugData) => void;

  store_baseMapPanelAnchorElement: HTMLButtonElement | undefined;
  store_setBaseMapPanelAnchorElement: (newValue: HTMLButtonElement | undefined) => void;
  store_layersPanelAnchorElement: HTMLButtonElement | undefined;
  store_setLayersPanelAnchorElement: (newValue: HTMLButtonElement | undefined) => void;
  store_accountMenuAnchorElement: HTMLButtonElement | undefined;
  store_setAccountMenuAnchorElement: (newValue: HTMLButtonElement | undefined) => void;

  store_feedbackWindowOpen: boolean;
  store_setFeedbackWindowOpen: (newValue: boolean) => void;

  store_appSettingsOpen: boolean;
  store_setAppSettingsOpen: (newValue: boolean) => void;
  store_accountSettingsOpen: boolean;
  store_setAccountSettingsOpen: (newValue: boolean) => void;
  store_showTermsOfServiceWindow: boolean;
  store_setShowTermsOfServiceWindow: (newValue: boolean) => void;

  // Reset the entire store to defaults (used when logging out)
  store_reset: () => void;
};

//-------------------------------------------------------------------------------
// Zustand state store
//-------------------------------------------------------------------------------
export const useStore = create<IStore>((set, get) => (
{
  // Project
  // ----------------------------------------------------------------------------------------

  store_orgProjectsList: [],
  store_setOrgProjectsList: (newList: IOrgProjects[]) => { set(() => ({ store_orgProjectsList: newList })); },

  store_removeProjectFromList: (projectID: string) => 
  {
    // Create a new orgProjectsList, but with the specified project missing (from any org)

    const newOrgProjectsList: IOrgProjects[] = get().store_orgProjectsList;
    for(let i=0; i < newOrgProjectsList.length; i++)
      newOrgProjectsList[i].projects = newOrgProjectsList[i].projects.filter(item => item.project_id !== projectID)

    set(() => ({ store_orgProjectsList: newOrgProjectsList }));
  },

  store_projectListIsLoading: false,
  store_setProjectListIsLoading: (newValue: boolean) => { set(() => ({ store_projectListIsLoading: newValue })); },

  store_projectUIMode: 'default',
  store_setProjectUIMode: (newValue: ProjectUIMode) => { set(() => ({ store_projectUIMode: newValue })); },

  // store_setProjectListShareState: (projectID: string, newValue: boolean) => 
  // {
  //   // Find the item in the project list

  //   const projectList: IProjectListItem[] = get().store_projectList;

  //   let projectListItem: IProjectListItem | null = null;
  //   for(let i=0; i < projectList.length; i++)
  //     if(projectList[i].project_id === projectID)
  //     {
  //       projectListItem = projectList[i];
  //       break;
  //     }

  //   // Prepare a new updated version of the item's 'sharing_info'

  //   if(!projectListItem || !projectListItem.sharing_info)
  //     return;

  //   const new_sharing_info: IProjectUserSettingsSharingInfo = 
  //   {
  //     ...projectListItem.sharing_info,
  //     shared_new: newValue
  //   }

  //   // Now we just need to apply this new item to the project list

  //   set((state) => (
  //     {
  //       store_projectList: state.store_projectList.map(projectListItem =>
  //         projectListItem.project_id === projectID ? ({ ...projectListItem,  sharing_info: new_sharing_info } as IProjectListItem) : projectListItem
  //       ),
  //     }));
  // },

  store_project: null,
  store_setProject: (newProject: IProject | null) => { set(() => ({ store_project: newProject })); },

  store_setProjectActiveBundle: (newBundleID: number) => 
  {
    set((state) => ({ store_project: state.store_project ? { ...state.store_project, bundle_id: newBundleID } : null, }));
  },

  // store_setProjectClientSideLoadDate: (newValue: Date) => 
  // {
  //   set((state) => ({ store_project: state.store_project ? { ...state.store_project, client_side_load_date: newValue } : null }));
  // },

  store_setProjectServerSideLoadDate: (newValue: string | undefined) => 
  {
    set((state) => ({ store_project: state.store_project ? { ...state.store_project, load_date: newValue } : null }));
  },

  store_setProjectIsDirty: (newValue: boolean) => 
  {
    // If this request happens within 3 seconds of the project being loaded, ignore it.
    // This is done because various things trigger the project's "isDirty" flag during the loading process,
    // and while it's possible to handle it everywhere, it complicates code all over the place.  For now
    // this method addresses the issue and seems cleaner.
    const activeProject: IProject | null = get().store_project;
    let secondsSinceProjectLoad = -1;
    if(activeProject && activeProject.client_side_load_date)
    {
      secondsSinceProjectLoad = Math.abs(new Date().getTime() - activeProject.client_side_load_date.getTime())/1000;
      if(secondsSinceProjectLoad < 3)
        return;
    }

    set((state) => ({ store_project: state.store_project ? { ...state.store_project, isDirty: newValue } : null }));
  },

  store_projectIsLoading: false,
  store_setProjectIsLoading: (newValue: boolean) => { set(() => ({ store_projectIsLoading: newValue })); },
  
  store_projectSurpressSaving: false,

  store_setProjectName: (newName: string) =>
  {
    set((state) => ({ store_project: state.store_project ? { ...state.store_project, name: newName } : null }));
  },

  store_setProjectOrg: (newOrgID: number | undefined) =>
  {
    set((state) => ({ store_project: state.store_project ? { ...state.store_project, organization_id: newOrgID } : null }));
  },

  store_projectRename: (projectID: string, newName: string) => 
  {
    // Update the project name in the project list

    // set((state) => (
    // {
    //   store_projectList: state.store_projectList.map(projectListItem =>
    //     projectListItem.project_id === projectID ? ({ ...projectListItem, name: newName } as IProjectListItem) : projectListItem
    //   ),
    // }));

    // If this is the ID of the active project, update it as well

    const activeProject: IProject | null = get().store_project;
    if(activeProject && activeProject.project_id === projectID)
      set((state) => (
      {
        store_project: state.store_project ? { ...state.store_project, name: newName } : null
      }));
  },

  store_setProjectUserSettings: (newValue: IProjectUserSettings) =>
  {
    set((state) => ({ store_project: state.store_project ? { ...state.store_project, user_settings: newValue } : null }));
  },

  store_setProjectMapView: (newValue: IMapView) =>
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newUserSettings: IProjectUserSettings =
    {
      ...activeProject.user_settings,
      mapView: newValue
    }

    const newProject: IProject =
    {
      ...activeProject,
      user_settings: newUserSettings
    }

    set((state) => ({ store_project: newProject }));
  },

  store_setProjectLastActiveAoi: (newValue: number | null) =>
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newUserSettings: IProjectUserSettings =
    {
      ...activeProject.user_settings,
      lastActiveAoiId: newValue
    }

    const newProject: IProject =
    {
      ...activeProject,
      user_settings: newUserSettings
    }

    set((state) => ({ store_project: newProject }));
  },

  store_setProject_IdentifyShowEmptyValues: (newValue: boolean) =>
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newUserSettings: IProjectUserSettings =
    {
      ...activeProject.user_settings,
      identify_showEmptyValues: newValue
    }

    const newProject: IProject =
    {
      ...activeProject,
      user_settings: newUserSettings
    }

    set((state) => ({ store_project: newProject }));
  },  

  store_setProject_IdentifyShowOnlyParcelUserAttribs: (newValue: boolean) =>
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newUserSettings: IProjectUserSettings =
    {
      ...activeProject.user_settings,
      identify_showOnlyParcelUserAttributes: newValue
    }

    const newProject: IProject =
    {
      ...activeProject,
      user_settings: newUserSettings
    }

    set((state) => ({ store_project: newProject }));
  },  

  store_setProject_UserValues: (newValue: IProjectUserSettingsUserValue[]) =>
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newUserSettings: IProjectUserSettings =
    {
      ...activeProject.user_settings,
      user_values: newValue
    }

    const newProject: IProject =
    {
      ...activeProject,
      user_settings: newUserSettings
    }

    set((state) => ({ store_project: newProject }));
  },  

  store_projectAddAoi: (newAoiListItem: IProjectAoi) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newProject: IProject =
    {
      ...activeProject,
      aois: [...activeProject.aois, newAoiListItem]
    }

    set((state) => ({ store_project: newProject }));
  },

  store_projectRemoveAoi: (aoi_id: number) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;
    
    const newProject: IProject =
    {
      ...activeProject,
      aois: activeProject.aois.filter((aoi) => aoi.aoi_id !== aoi_id)
    }

    set((state) => ({ store_project: newProject }));
  },

  store_projectRenameAoi: (aoi_id: number, newName: string) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newProject: IProject =
    {
      ...activeProject,
      aois: activeProject.aois.map(aoi => aoi.aoi_id === aoi_id ? ({ ...aoi, aoi_name: newName } as IProjectAoi) : aoi)
    }

    set((state) => ({ store_project: newProject }));
  },

  store_projectUpdateAoi: (newValue: IProjectAoi) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newProject: IProject =
    {
      ...activeProject,
      aois: activeProject.aois.map(aoi => aoi.aoi_id === newValue.aoi_id ? newValue : aoi)
    }

    set((state) => ({ store_project: newProject }));
  },

  store_projectAddScenario: (newItem: IProjectScenario) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newProject: IProject =
    {
      ...activeProject,
      scenarios: [...activeProject.scenarios, newItem]
    }

    set((state) => ({ store_project: newProject }));
  },

  store_projectUpdateScenario: (newValue: IProjectScenario) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newProject: IProject =
    {
      ...activeProject,
      scenarios: activeProject.scenarios.map(scenario => scenario.hbv_scenario_id === newValue.hbv_scenario_id ? newValue : scenario)
    }

    set((state) => ({ store_project: newProject }));
  },

  store_projectRemoveScenario: (scenario_id: number) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;
    
    const newProject: IProject =
    {
      ...activeProject,
      scenarios: activeProject.scenarios.filter(scenario => scenario.hbv_scenario_id !== scenario_id)
    }

    set((state) => ({ store_project: newProject }));
  },


  store_orgUsersLoading: false,
  store_setOrgUsersLoading: (newValue: boolean) => { set(() => ({ store_orgUsersLoading: newValue })); },

  store_projectSharingOrgs: [],
  store_setProjectSharingOrgs: (newValue: IProjectSharingOrg[]) => { set(() => ({ store_projectSharingOrgs: newValue })); },


  store_setProjectHbvScenarioSelected: (scenario_id: number, newValue: boolean) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newProject: IProject =
    {
      ...activeProject,
      scenarios: activeProject.scenarios.map(scenario => scenario.hbv_scenario_id === scenario_id ? ({ ...scenario, selected: newValue } as IProjectScenario) : scenario)
    }

    set((state) => ({ store_project: newProject }));
  },

  store_addProjectHbvScenario: (newScenario: IProjectScenario) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newProject: IProject =
    {
      ...activeProject,
      scenarios: [...activeProject.scenarios, newScenario],
    }

    set((state) => ({ store_project: newProject }));
  },

  store_removeProjectHbvScenario: (hbvScenarioID: number) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newProject: IProject =
    {
      ...activeProject,
      scenarios: activeProject.scenarios.filter(scenario => scenario.hbv_scenario_id !== hbvScenarioID)
    }

    set((state) => ({ store_project: newProject }));
  },

  store_setProjectHbvScenarioName: (hbv_scenario_id: number, newName: string) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newProject: IProject =
    {
      ...activeProject,
      scenarios: activeProject.scenarios.map(scenario => scenario.hbv_scenario_id === hbv_scenario_id ? ({ ...scenario, name: newName.trim() } as IProjectScenario) : scenario),
    }

    set((state) => ({ store_project: newProject }));
  },

  store_setProjectHbvScenarioInfo: (hbv_scenario_id: number, newDollars: number, newLastRunDate: string) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newProject: IProject =
    {
      ...activeProject,
      scenarios: activeProject.scenarios.map(scenario => scenario.hbv_scenario_id === hbv_scenario_id ? ({ ...scenario, dollars: newDollars, last_run_date: newLastRunDate } as IProjectScenario) : scenario),
    }

    set((state) => ({ store_project: newProject }));
  },

  store_setProjectParcelAttribList: (newAttribList: IProjectParcelAttribute[]) =>
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newUserSettings: IProjectUserSettings = 
    {
      ...activeProject.user_settings,
      parcel_attribute_list: newAttribList
    }

    const newProject: IProject =
    {
      ...activeProject,
      user_settings: newUserSettings,
    }

    set((state) => ({ store_project: newProject }));
  },

  store_addProjectParcelAttrib: (newValue: IProjectParcelAttribute) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newUserSettings: IProjectUserSettings = 
    {
      ...activeProject.user_settings,
      parcel_attribute_list: [...activeProject.user_settings.parcel_attribute_list, newValue ]
    }

    const newProject: IProject =
    {
      ...activeProject,
      user_settings: newUserSettings,
    }

    set((state) => ({ store_project: newProject }));
  },

  store_removeProjectParcelAttrib: (attribute_name: string) => 
  {
    const activeProject: IProject | null = get().store_project;  
    if(!activeProject) return;

    const newUserSettings: IProjectUserSettings = 
    {
      ...activeProject.user_settings,
      parcel_attribute_list: activeProject.user_settings.parcel_attribute_list.filter(attrib => attrib.name !== attribute_name)
    }

    const newProject: IProject =
    {
      ...activeProject,
      user_settings: newUserSettings,
    }

    set((state) => ({ store_project: newProject }));
  },






  // Bundles | NRRs | Impacts
  // ----------------------------------------------------------------------------------------

  store_bundleList: [],
  store_setBundleList: (newList: IBundleListItem[]) => { set(() => ({ store_bundleList: newList })); },
  store_bundleListIsLoading: false,
  store_setBundleListIsLoading: (newValue: boolean) => { set(() => ({ store_bundleListIsLoading: newValue })); },

  store_bundle: null,
  store_setBundle: (newBundle: IBundle | null) => { set(() => ({ store_bundle: newBundle })); },
  store_bundleUIMode: 'default',
  store_setBundleUIMode: (newValue: BundleUIMode) => { set(() => ({ store_bundleUIMode: newValue })); },
  store_bundleIsLoading: false,
  store_setBundleIsLoading: (newValue: boolean) => { set(() => ({ store_bundleIsLoading: newValue })); },

  store_nrrs: [],
  store_setNrrs: (newList: INRR[]) => { set(() => ({ store_nrrs: newList })); },

  store_setNrrExpanded: (nrr_id: number, newValue: boolean) => 
  {
    set((state) => (
    {
      store_nrrs: state.store_nrrs.map(nrr =>
        nrr.nrr_id === nrr_id ? ({ ...nrr, expanded: newValue } as INRR) : nrr
      ),
    }));
  },

  store_nrrImpacts: [],
  store_setNrrImpacts: (newList: INRRImpact[]) => { set(() => ({ store_nrrImpacts: newList })); },
  store_nrrImpactLinks: [],
  store_setNrrImpactLinks: (newList: INRRImpactLink[]) => { set(() => ({ store_nrrImpactLinks: newList })); },
  store_getNrrImpactLink: (nrr_id: number, impact_id: number): INRRImpactLink | undefined => 
  {
    const store_nrrImpactLinks = get().store_nrrImpactLinks;
    for(let i=0; i < store_nrrImpactLinks.length; i++)
      if(store_nrrImpactLinks[i].nrr_id === nrr_id && store_nrrImpactLinks[i].impact_id === impact_id)
        return store_nrrImpactLinks[i];

    return undefined;
  },

  store_editUserValues: false,
  store_setEditUserValues: (newValue: boolean) => { set(() => ({ store_editUserValues: newValue })); },
  store_editUserValuesNrrID: undefined,
  store_setEditUserValuesNrrID: (newValue: number | undefined) => { set(() => ({ store_editUserValuesNrrID: newValue })); },
  store_editUserValuesImpactID: undefined,
  store_setEditUserValuesImpactID: (newValue: number | undefined) => { set(() => ({ store_editUserValuesImpactID: newValue })); },

  // HBV
  // ----------------------------------------------------------------------------------------

  store_hbvScenario: null,
  store_setHbvScenario: (newValue: IHbvScenario | null) => { set(() => ({ store_hbvScenario: newValue })); },

  store_setHbvScenarioID: (newValue: number | null) => 
  {
    set((state) => ({ store_hbvScenario: state.store_hbvScenario ? { ...state.store_hbvScenario, scenario_id: newValue } : null }));
  },

  store_setHbvScenarioName: (newValue: string) => 
  {
    set((state) => ({ store_hbvScenario: state.store_hbvScenario ? { ...state.store_hbvScenario, name: newValue } : null }));
  },

  store_hbvIsRunning: false,
  store_setHbvIsRunning: (newValue: boolean) => { set(() => ({ store_hbvIsRunning: newValue })); },
  store_hbvRunInstanceID: null,
  store_setHbvRunInstanceID: (newValue: number | null) => { set(() => ({ store_hbvRunInstanceID: newValue })); },
  store_hbvScenarioIsLoading: false,
  store_setHbvScenarioIsLoading: (newValue: boolean) => { set(() => ({ store_hbvScenarioIsLoading: newValue })); },
  store_hbvScenarioCompareIsLoading: false,
  store_setHbvScenarioCompareIsLoading: (newValue: boolean) => { set(() => ({ store_hbvScenarioCompareIsLoading: newValue })); },
  store_hbvComparedScenarios: [],
  store_setHbvComparedScenarios: (newValue: IHbvScenario[]) => { set(() => ({ store_hbvComparedScenarios: newValue })); },
  store_hbvScenarioIsSaving: false,
  store_setHbvScenarioIsSaving: (newValue: boolean) => { set(() => ({ store_hbvScenarioIsSaving: newValue })); },
  store_hbvScenarioRefreshIsLoading: false,
  store_setHbvScenarioRefreshIsLoading: (newValue: boolean) => { set(() => ({ store_hbvScenarioRefreshIsLoading: newValue })); },

  store_chartColors: [],
  store_setChartColors: (newValue: IHbvChartColor[]) => { set(() => ({ store_chartColors: newValue })); },
  store_addChartColor: (newName: string, newColor: string) => 
  {
    const newChartColor: IHbvChartColor = 
    {
      name: newName,
      color: newColor
    }

    set((state) => (
    {
      store_chartColors: [...state.store_chartColors, newChartColor ] 
    }));
  },

  // AOI
  // ----------------------------------------------------------------------------------------

  store_aoi: null,
  store_setAoi: (newAoi: IAoi | null) => { set(() => ({ store_aoi: newAoi })); },
  store_aoiIsLoading: false,
  store_setAoiIsLoading: (newValue: boolean) => { set(() => ({ store_aoiIsLoading: newValue })); },
  store_aoiIsSaving: false,
  store_setAoiIsSaving: (newValue: boolean) => { set(() => ({ store_aoiIsSaving: newValue })); },
  store_aoiIsDeleting: false,
  store_setAoiIsDeleting: (newValue: boolean) => { set(() => (
  { 
    store_aoiIsDeleting: newValue,
    store_projectSurpressSaving: newValue
  }))},
 

  store_setAoiName: (newValue: string) => 
  {
    set((state) => ({ store_aoi: state.store_aoi ? { ...state.store_aoi, aoi_name: newValue } : null }));
  },  

  store_setAoiIsDirty: (newValue: boolean) => 
  {
    set((state) => ({ store_aoi: state.store_aoi ? { ...state.store_aoi, isDirty: newValue } : null }));
  },

  store_setAoiUpdatedGeom: (newValue: any) => 
  {
    set((state) => ({ store_aoi: state.store_aoi ? { ...state.store_aoi, updatedGeom: newValue } : null }));
  },

  store_setAoiLastSavedGeom: (newValue: any) => 
  {
    set((state) => ({ store_aoi: state.store_aoi ? { ...state.store_aoi, lastSavedGeom: newValue } : null }));
  },

  store_setAoiPolygonCount: (newValue: number) => 
  {
    set((state) => ({ store_aoi: state.store_aoi ? { ...state.store_aoi, polygonCount: newValue } : null }));
  },

  store_setAoiAcres: (newValue: number) => 
  {
    set((state) => ({ store_aoi: state.store_aoi ? { ...state.store_aoi, acres: newValue } : null }));
  },

  store_aoiFileIsUploading: false,
  store_setAoiFileIsUploading: (newValue: boolean) => { set(() => ({ store_aoiFileIsUploading: newValue })); },

  store_aoiIsExporting: false,
  store_setAoiIsExporting: (newValue: boolean) => { set(() => ({ store_aoiIsExporting: newValue })); },

  store_setAoiNote: (newValue: string) => 
  {
    const activeAoi: IAoi | null = get().store_aoi;
    if(!activeAoi) return;

    const newAoiNote: IAoiNote = 
    {
      //...activeAoi.properties?.note,
      text: newValue
    }

    const newAoiProperties: IAoiProperties =
    {
      ...activeAoi.properties,
      note: newAoiNote
    }

    set((state) => ({ store_aoi: state.store_aoi ? { ...state.store_aoi, properties: newAoiProperties } : null }));
  },

  store_aoiList: [],
  store_setAoiList: (newList: IAoiListItem[]) => { set(() => ({ store_aoiList: newList })); },
  store_aoiListIsLoading: false,
  store_setAoiListIsLoading: (newValue: boolean) => { set(() => ({ store_aoiListIsLoading: newValue })); },

  store_aoiUIMode: 'default',
  store_setAoiUIMode: (newValue: AoiUIMode) => { set(() => ({ store_aoiUIMode: newValue })); },

  // AOI group properties

  store_portfolioMapAois: [],
  store_setPortfolioMapAois: (newValue: IAoi[]) => { set(() => ({ store_portfolioMapAois: newValue })); },
  store_aoiGroupProperties: undefined,
  store_setAoiGroupProperties: (newValue: IAoiGroupProperties | undefined) => { set(() => ({ store_aoiGroupProperties: newValue })); },
  store_editAoiGroupProperties: false,
  store_setEditAoiGroupProperties: (newValue: boolean) => { set(() => ({ store_editAoiGroupProperties: newValue })); },
  store_aoiGroupPropertiesIsSaving: false,
  store_setAoiGroupPropertiesIsSaving: (newValue: boolean) => { set(() => ({ store_aoiGroupPropertiesIsSaving: newValue })); },

  // AOI attributes

  store_editAoiAttributes: false,
  store_setEditAoiAttributes: (newValue: boolean) => { set(() => ({ store_editAoiAttributes: newValue })); },

  // Map
  // ----------------------------------------------------------------------------------------

  store_map: null,
  store_setMap: (newValue: mapboxgl.Map | null) => { set(() => ({ store_map: newValue })); },

  store_mapSearchControl: null,
  store_setMapSearchControl: (newValue: MapboxGeocoder | null) => { set(() => ({ store_mapSearchControl: newValue })); },
  store_mapDrawControl: null,
  store_setMapDrawControl: (newValue: MapboxDraw | null) => { set(() => ({ store_mapDrawControl: newValue })); },
  store_mapScaleControl: null,
  store_setMapScaleControl: (newValue: mapboxgl.ScaleControl | null) => { set(() => ({ store_mapScaleControl: newValue })); },
  store_mapCompassControl: null,
  store_setMapCompassControl: (newValue: mapboxgl.NavigationControl | null) => { set(() => ({ store_mapCompassControl: newValue })); },
  store_mapGeolocateControl: null,
  store_setMapGeolocateControl: (newValue: mapboxgl.GeolocateControl | null) => { set(() => ({ store_mapGeolocateControl: newValue })); },

  store_baseMap: BaseMap.SatelliteStreets,
  store_setBaseMap: (newValue: BaseMap) => { set(() => ({ store_baseMap: newValue })); },

  store_threeDTerrain: false,
  store_setThreeDTerrain: (newValue: boolean) => { set(() => ({ store_threeDTerrain: newValue })); },

  // Layers
  // ----------------------------------------------------------------------------------------

  store_layers: [],

  store_setLayers: (newValue: ILayer[]) => { set(() => ({ store_layers: newValue })); },

  store_addLayer: (newLayer: ILayer) => 
  {
    set((state) => (
    {
      store_layers: [...state.store_layers, newLayer] 
    }));
  },

  store_addOrUpdateLayer: (newOrUpdatedLayer: ILayer) => 
  {
    const store_layers = get().store_layers;

    // Determine if a layer with this id already exists or not

    let layerAlreadyExists: boolean = false;
    for(let i=0; i < store_layers.length; i++)
      if(store_layers[i].id === newOrUpdatedLayer.id)
      {
        layerAlreadyExists = true;
        continue;
      }
    
    if(layerAlreadyExists)
    {
      // The layer already exists, so we just need to update it (without changing
      // its order in the list).  To do that we create a new layer list, but replace
      // the old layer with the updated layer.

      let newLayerList: ILayer[] = [];
      const store_layers = get().store_layers;
      for(let i=0; i < store_layers.length; i++)
        if(store_layers[i].id === newOrUpdatedLayer.id)
          newLayerList.push(newOrUpdatedLayer);
        else
          newLayerList.push(store_layers[i]);

      set(() => ({ store_layers: newLayerList }));
    }
    else
    {
      // This is a new layer - add it
      set((state) => ({ store_layers: [...state.store_layers, newOrUpdatedLayer] }));
    }
  },

  store_removeLayer: (id: number) => 
  {
    set((state) => ({ store_layers: state.store_layers.filter((layer) => layer.id !== id) }));
  },

  store_setLayerEnabled: (id: number, newState: boolean) => 
  {
    set((state) => (
    {
      store_layers: state.store_layers.map(layer =>
        layer.id === id ? ({ ...layer, enabled: newState } as ILayer) : layer
      ),
    }));
  },

  store_setLayerOpacity: (id: number, newValue: number) => 
  {
    set((state) => (
    {
      store_layers: state.store_layers.map(layer =>
        layer.id === id ? ({ ...layer, opacity: newValue } as ILayer) : layer
      ),
    }));
  },

  store_setLayerExpanded: (id: number, newValue: boolean) => 
  {
    set((state) => (
    {
      store_layers: state.store_layers.map(layer =>
        layer.id === id ? ({ ...layer, expanded: newValue } as ILayer) : layer
      ),
    }));
  },

  store_setLayerLegend: (id: number, newValue: ILegendEntry[]) => 
  {
    set((state) => (
    {
      store_layers: state.store_layers.map(layer =>
        layer.id === id ? ({ ...layer, legendEntries: newValue } as ILayer) : layer
      ),
    }));
  },

  store_setLayerType: (id: number, newValue: MapboxLayerType) => 
  {
    set((state) => (
    {
      store_layers: state.store_layers.map(layer =>
        layer.id === id ? ({ ...layer, mapboxLayerType: newValue } as ILayer) : layer
      ),
    }));
  },
  
  store_setLayerStyle: (id: number, newPaint: any) => 
  {
    set((state) => (
    {
      store_layers: state.store_layers.map(layer =>
        layer.id === id ? ({ ...layer, mapboxPaint: newPaint } as ILayer) : layer
      ),
    }));
  },

  store_setLegendShowAll: (id: number, newValue: boolean) => 
  {
    set((state) => (
    {
      store_layers: state.store_layers.map(layer =>
        layer.id === id ? ({ ...layer, legendShowAll: newValue } as ILayer) : layer
      ),
    }));
  },

  store_layersForLegendPanel: () =>
  {
    let layerList: ILayer[] = [];
    for(let i=0; i < get().store_layers.length; i++)
    {
      const layer = get().store_layers[i];
      if(layer.enabled && !layer.hidden/* && layer.legend && layer.legend.entries && layer.legend.entries.length >= 1*/)
        layerList.push(layer)
    }

    return layerList;
  },

  store_activeInfoLayer: null,
  store_setActiveInfoLayer: (newValue: ILayer | null) => { set(() => ({ store_activeInfoLayer: newValue })); },

  // Layer library
  
  store_layerLibraryGroups: [],
  store_setLayerLibraryGroups: (newValue: ILayerLibraryGroup[]) => { set(() => ({ store_layerLibraryGroups: newValue })); },

  store_layerLibraryActive: false,
  store_setLayerLibraryActive: (newValue: boolean) => { set(() => ({ store_layerLibraryActive: newValue })); },

  store_addLayerLibraryGroup: (newGroup: ILayerLibraryGroup) => 
  {
    set((state) => (
    {
      store_layerLibraryGroups: [...state.store_layerLibraryGroups, newGroup] 
    }));
  },

  store_removeLayerLibraryGroup: (groupID: number) => 
  {
    set((state) => ({ store_layerLibraryGroups: state.store_layerLibraryGroups.filter((group) => group.layer_library_group_id !== groupID) }));
  },

  store_setLayerLibraryGroupName: (groupID: number, newGroupName: string) =>
  {
    const newLayerLibraryGroups = get().store_layerLibraryGroups.map(group => group.layer_library_group_id === groupID ? ({ ...group, name: newGroupName } as ILayerLibraryGroup) : group)
    set((state) => ({ store_layerLibraryGroups: newLayerLibraryGroups }));
  },

  store_creatingNewLayer: false,
  store_setCreatingNewLayer: (newValue: boolean) => { set(() => ({ store_creatingNewLayer: newValue })); },
  store_deletingLayer: false,
  store_setDeletingLayer: (newValue: boolean) => { set(() => ({ store_deletingLayer: newValue })); },

  // Layer editor

  store_editLayer: undefined,
  store_setEditLayer: (newValue: ILayer | undefined) => { set(() => ({ store_editLayer: newValue })); },
  store_editLayerFromLayerLibrary: false,
  store_setEditLayerFromLayerLibrary: (newValue: boolean) => { set(() => ({ store_editLayerFromLayerLibrary: newValue })); },
  store_uploadingLegendImage: false,
  store_setUploadingLegendImage: (newValue: boolean) => { set(() => ({ store_uploadingLegendImage: newValue })); },
  store_uploadingMapImage: false,
  store_setUploadingMapImage: (newValue: boolean) => { set(() => ({ store_uploadingMapImage: newValue })); },

  // Base map bundle layers
  // ----------------------------------------------------------------------------------------

  store_baseMapLayer_labels: { name:'', enabled:false, opacity:1, expanded:false, subLayerMatchList:[] },
  store_baseMapLayer_states: { name:'', enabled:false, opacity:1, expanded:false, subLayerMatchList:[] },
  store_baseMapLayer_roads:  { name:'', enabled:false, opacity:1, expanded:false, subLayerMatchList:[] },
  store_baseMapLayer_water:  { name:'', enabled:false, opacity:1, expanded:false, subLayerMatchList:[] },

  store_setBaseMapLayer: (newName: string, newOpacity: number, newSubLayerMatchList: string[]) => 
  {
    const newLayer: IBaseMapLayer = 
    {
      name: newName,
      enabled: true,
      opacity: newOpacity,
      expanded: false,
      subLayerMatchList: newSubLayerMatchList,
    };

    switch(newName.toLowerCase())
    {
      case 'labels': set(() => ({ store_baseMapLayer_labels: newLayer })); return;
      case 'states': set(() => ({ store_baseMapLayer_states: newLayer })); return;
      case 'roads':  set(() => ({ store_baseMapLayer_roads: newLayer })); return;
      case 'water':  set(() => ({ store_baseMapLayer_water: newLayer })); return;
    }
  },

  store_activateBaseMapLayer: (newName: string, newState: boolean) =>
  {
    switch(newName.toLowerCase())
    {
      case 'labels': set((state) => ({ store_baseMapLayer_labels: {...state.store_baseMapLayer_labels, enabled: newState} as IBaseMapLayer })); return;
      case 'states': set((state) => ({ store_baseMapLayer_states: {...state.store_baseMapLayer_states, enabled: newState} as IBaseMapLayer })); return;
      case 'roads':  set((state) => ({ store_baseMapLayer_roads: {...state.store_baseMapLayer_roads, enabled: newState} as IBaseMapLayer })); return;
      case 'water':  set((state) => ({ store_baseMapLayer_water: {...state.store_baseMapLayer_water, enabled: newState} as IBaseMapLayer })); return;
    }
  },

  // Parcels
  // ----------------------------------------------------------------------------------------

  store_selectedParcels: [],
  store_setSelectedParcels: (newValue: IParcel[]) => { set(() => ({ store_selectedParcels: newValue })); }, 

  store_addSelectedParcel: (newItem: IParcel) => 
  {
    set((state) => (
    {
      store_selectedParcels: [...state.store_selectedParcels, newItem] 
    }));
  },

  store_removeSelectedParcel: (id: number) => 
  {
    set((state) => ({ store_selectedParcels: state.store_selectedParcels.filter((parcel) => parcel.id !== id) }));
  },

  store_selectedParcelsTotalAcres: undefined,
  store_setSelectedParcelsTotalAcres: (newValue: number | undefined) => { set(() => ({ store_selectedParcelsTotalAcres: newValue })); }, 
  store_parcelEditMode: false,
  store_setParcelEditMode: (newValue: boolean) => { set(() => ({ store_parcelEditMode: newValue })); }, 

  store_parcelExtraAttributeList: [],
  store_setParcelExtraAttributeList: (newValue: IProjectParcelAttribute[]) => { set(() => ({ store_parcelExtraAttributeList: newValue })); }, 

  store_addParcelExtraAttribute: (attribute_name: string) => 
  {
    const newAttribute: IProjectParcelAttribute = 
    {
      name: attribute_name,
    }

    set((state) => (
    {
      store_parcelExtraAttributeList: [...state.store_parcelExtraAttributeList, newAttribute ] 
    }));
  },

  store_removeParcelExtraAttribute: (attribute_name: string) => 
  {
    set((state) => ({ store_parcelExtraAttributeList: state.store_parcelExtraAttributeList.filter((attrib) => attrib.name !== attribute_name) }));
  },

  // store_removeFromExtraAttributeList: (attribute_name: string) => 
  // {
  //   //set((state) => ({ store_parcelExtraAttributeList: state.store_parcelExtraAttributeList.filter((attrib) => attrib.name !== attribute_name) }));
  // },

  // Parcel search

  store_parcelSearchExpression: { clauses:[], operator: 'and', states: [], attributes: [] },
  store_setParcelSearchExpression: (newValue: IParcelSearchExpression) => { set(() => ({ store_parcelSearchExpression: newValue })); }, 

  store_setParcelSearchExpressionOperator: (newValue: TParcelSearchExpressionOperator) => 
  {
    const parcelSearchExpression: IParcelSearchExpression = get().store_parcelSearchExpression;

    const newParcelSearchExpression: IParcelSearchExpression =
    {
      ...parcelSearchExpression,
      operator: newValue
    }

    set((state) => ({ store_parcelSearchExpression: newParcelSearchExpression }));
  },

  store_addParcelSearchClause: (newItem: IParcelSearchClause) => 
  {
    const parcelSearchExpression: IParcelSearchExpression = get().store_parcelSearchExpression;

    const newParcelSearchExpression: IParcelSearchExpression =
    {
      ...parcelSearchExpression,
      clauses: [...parcelSearchExpression.clauses, newItem],
    }

    set((state) => ({ store_parcelSearchExpression: newParcelSearchExpression }));
  },

  store_removeParcelSearchClause: (clause_id: number) => 
  {
    const parcelSearchExpression: IParcelSearchExpression = get().store_parcelSearchExpression;

    const newParcelSearchExpression: IParcelSearchExpression =
    {
      ...parcelSearchExpression,
      clauses: parcelSearchExpression.clauses.filter((clause) => clause.clause_id !== clause_id)
    }

    set((state) => ({ store_parcelSearchExpression: newParcelSearchExpression }));
  },

  store_updateParcelSearchClause: (newClause: IParcelSearchClause) =>
  {
    const parcelSearchExpression: IParcelSearchExpression = get().store_parcelSearchExpression;

    const newParcelSearchExpression: IParcelSearchExpression =
    {
      ...parcelSearchExpression,
      clauses: parcelSearchExpression.clauses.map(oldClause => oldClause.clause_id === newClause.clause_id ? newClause : oldClause)
    }

    set((state) => ({ store_parcelSearchExpression: newParcelSearchExpression }));
  },

  store_parcelSearchIsRunning: false,
  store_setParcelSearchIsRunning: (newValue: boolean) => { set(() => ({ store_parcelSearchIsRunning: newValue })); },
  store_parcelSearchEnabled: false,
  store_setParcelSearchEnabled: (newValue: boolean) => { set(() => ({ store_parcelSearchEnabled: newValue })); },
  store_parcelSearchRunInstanceID: undefined,
  store_setParcelSearchRunInstanceID: (newValue: number | undefined) => { set(() => ({ store_parcelSearchRunInstanceID: newValue })); },
  store_parcelSearchMaxResults: PARCEL_SEARCH_DEFAULT_MAX_RESULTS,
  store_setParcelSearchMaxResults: (newValue: TParcelSearchMaxResults) => { set(() => ({ store_parcelSearchMaxResults: newValue })); },
  store_parcelSearchDbSortAttribName: undefined,
  store_setParcelSearchDbSortAttribName: (newValue: string | undefined) => { set(() => ({ store_parcelSearchDbSortAttribName: newValue })); },
  store_parcelSearchDbSortOrderDesc: false,
  store_setParcelSearchDbSortOrderDesc: (newValue: boolean) => { set(() => ({ store_parcelSearchDbSortOrderDesc: newValue })); },
  store_parcelSearchStates: [],
  store_setParcelSearchStates: (newValue: TStateAbbreviation[]) => { set(() => ({ store_parcelSearchStates: newValue })); },
  store_parcelSearchAreaFilter: 'None',
  store_setParcelSearchAreaFilter: (newValue: TParcelSearchAreaFilter) => { set(() => ({ store_parcelSearchAreaFilter: newValue })); },
  store_parcelSearchResultsViewerMode: false,
  store_setParcelSearchResultsViewerMode: (newValue: boolean) => { set(() => ({ store_parcelSearchResultsViewerMode: newValue })); },
  store_parcelSearchAOIBufferMiles: "0",
  store_setParcelSearchAOIBufferMiles: (newValue: string) => { set(() => ({ store_parcelSearchAOIBufferMiles: newValue })); },

  // Parcel table viewer

  store_parcelTableViewer: false,
  store_setParcelTableViewer: (newValue: boolean) => { set(() => ({ store_parcelTableViewer: newValue })); },
  //store_parcelTableViewerVertScroll: 0,
  //store_setParcelTableViewerVertScroll: (newValue: number) => { set(() => ({ store_parcelTableViewerVertScroll: newValue })); },
  store_parcelTableLocalSortAttribName: undefined,
  store_setParcelTableLocalSortAttribName: (newValue: string | undefined) => { set(() => ({ store_parcelTableLocalSortAttribName: newValue })); },
  store_parcelTableLocalSortOrder: undefined,
  store_setParcelTableLocalSortOrder: (newValue: TSortOrder | undefined) => { set(() => ({ store_parcelTableLocalSortOrder: newValue })); },
  store_parcelsTableCurrPage: 0,
  store_setParcelsTableCurrPage: (newValue: number) => { set(() => ({ store_parcelsTableCurrPage: newValue })); },

  // Parcel attribute selector

  store_parcelAttribSelector: false,
  store_setParcelAttribSelector: (newValue: boolean) => { set(() => ({ store_parcelAttribSelector: newValue })); },


  // User account
  // ----------------------------------------------------------------------------------------

  store_username: '',
  store_setUsername: (newUsername: string) => { set(() => ({ store_username: newUsername })); },  

  store_idToken: '',
  store_setIdToken: (newIdToken: string) => { set(() => ({ store_idToken: newIdToken })); },

  store_accessToken: '',
  store_setAccessToken: (newAccessToken: string) => { set(() => ({ store_accessToken: newAccessToken })); },

  store_refreshToken: '',
  store_setRefreshToken: (newRefreshToken: string) => { set(() => ({ store_refreshToken: newRefreshToken })); },

  store_isLoggedIn: false,
  store_setIsLoggedIn: (newIsLoggedIn: boolean) => { set(() => ({ store_isLoggedIn: newIsLoggedIn })); },

  store_userInfo: null,
  store_setUserInfo: (newValue: IUserInfo | null) => { set(() => ({ store_userInfo: newValue })); },

  store_pwdResetRunningStep1: false,
  store_setPwdResetRunningStep1: (newValue: boolean) => { set(() => ({ store_pwdResetRunningStep1: newValue })); },

  store_pwdResetRunningStep2: false,
  store_setPwdResetRunningStep2: (newValue: boolean) => { set(() => ({ store_pwdResetRunningStep2: newValue })); },

  store_logInDate: null,
  store_setLogInDate: (newValue: Date | null) => { set(() => ({ store_logInDate: newValue })); },

  store_forcedLogout: false,
  store_setForcedLogout: (newValue: boolean) => { set(() => ({ store_forcedLogout: newValue })); },
  

  // User profile (includes app settings)
  // ----------------------------------------------------------------------------------------

  store_userProfile: null,
  store_setUserProfile: (newValue: IUserProfile | null) => { set(() => ({ store_userProfile: newValue })); },

  store_userProfileIsLoading: false,
  store_setUserProfileIsLoading: (newValue: boolean) => { set(() => ({ store_userProfileIsLoading: newValue })); },

  store_setUserProfileIsDirty: (newValue: boolean) => set((state) => (
  {
    store_userProfile: state.store_userProfile ? { ...state.store_userProfile, isDirty: newValue } : null
  })),

  store_setUserProfileMapScale: (newValue: boolean) => set((state) => (
  {
    store_userProfile: state.store_userProfile ? { ...state.store_userProfile, map_scale: newValue } : null
  })),

  store_setUserProfileMapScaleUnits: (newValue: MapScaleUnits) => set((state) => (
  {
    store_userProfile: state.store_userProfile ? { ...state.store_userProfile, map_scale_units: newValue } : null
  })),

  store_setUserProfileMapCompass: (newValue: boolean) => set((state) => (
  {
    store_userProfile: state.store_userProfile ? { ...state.store_userProfile, map_compass: newValue } : null
  })),

  store_setUserProfileMapGeolocate: (newValue: boolean) => set((state) => (
  {
    store_userProfile: state.store_userProfile ? { ...state.store_userProfile, map_geolocate: newValue } : null
  })),

  store_setUserProfileActiveProjectID: (newProjectID: string | null) => set((state) => (
  {
    store_userProfile: state.store_userProfile ? { ...state.store_userProfile, active_project_id: newProjectID } : null
  })),

  store_setUserProfileTerrain3DExaggeration: (newValue: number) => set((state) => (
  {
    store_userProfile: state.store_userProfile ? { ...state.store_userProfile, terrain_3d_exaggeration: newValue } : null
  })),
  store_setUserProfileAgreedToTermsOfService: (newValue: boolean) => set((state) => (
  {
    store_userProfile: state.store_userProfile ? { ...state.store_userProfile, agreed_to_terms_of_service: newValue } : null
  })),

  // Permissions
  // ----------------------------------------------------------------------------------------

  store_permCreateLayerLibraryGroup: false,
  store_setPermCreateLayerLibraryGroup: (newValue: boolean) => { set(() => ({ store_permCreateLayerLibraryGroup: newValue })); },

  store_permEditMainLayer: false,
  store_setPermEditMainLayer: (newValue: boolean) => { set(() => ({ store_permEditMainLayer: newValue })); },
    
  // Identify
  // ----------------------------------------------------------------------------------------

  store_identify: undefined,
  store_setIdentify: (newValue?: IIdentifyData) => { set(() => ({ store_identify: newValue })); },
  store_setIdentifyIsLoading: (newValue: boolean) => set((state) => (
  {
    store_identify: state.store_identify ? { ...state.store_identify, isLoading: newValue } : undefined
  })),  

  store_lastIdentifySelectionID: undefined,
  store_setLastIdentifySelectionID: (id?: number) => { set(() => ({ store_lastIdentifySelectionID: id })); },
  store_identifySettingsOpen: false,
  store_setIdentifySettingsOpen: (newValue: boolean) => { set(() => ({ store_identifySettingsOpen: newValue })); },

  // Drawer menu
  // ----------------------------------------------------------------------------------------

  store_drawerOpen: true,
  store_setDrawerOpen: (newValue: boolean) => { set(() => ({ store_drawerOpen: newValue })); },

  store_activeDrawerMenuItem: 'none',
  store_setActiveDrawerMenuItem: (newValue: DrawerMenuItemType) => { set(() => ({ store_activeDrawerMenuItem: newValue })); },

  // Misc
  // ----------------------------------------------------------------------------------------

  store_toastNotification: null,
  store_setToastNotification: (newValue: IToastNotification | null) => { set(() => ({ store_toastNotification: newValue })); },

  store_setToastNotificationOpen: (newValue: boolean) => set((state) => (
  {
    store_toastNotification: state.store_toastNotification ? { ...state.store_toastNotification, isOpen: newValue } : null
  })),

  store_forcedAppReload: false,
  store_setForcedAppReload: (newValue: boolean) => { set(() => ({ store_forcedAppReload: newValue })); },
  store_APIDebugMode: false,
  store_setAPIDebugMode: (newValue: boolean) => { set(() => ({ store_APIDebugMode: newValue })); }, 
  store_APIDebugData: [],
  store_setAPIDebugData: (newValue: IAPIDebugData[]) => { set(() => ({ store_APIDebugData: newValue })); }, 

  store_addAPIDebugData: (newValue: IAPIDebugData) => 
  {
    const data: IAPIDebugData[] = get().store_APIDebugData;
    data.push(newValue);
    if(data.length > API_DEBUG_MAX_ENTRIES)
      data.shift();

    set((state) => (
    {
      store_APIDebugData: [...data] 
    }));
  },

  store_baseMapPanelAnchorElement: undefined,
  store_setBaseMapPanelAnchorElement: (newValue: HTMLButtonElement | undefined) => { set(() => ({ store_baseMapPanelAnchorElement: newValue })); }, 
  store_layersPanelAnchorElement: undefined,
  store_setLayersPanelAnchorElement: (newValue: HTMLButtonElement | undefined) => { set(() => ({ store_layersPanelAnchorElement: newValue })); }, 
  store_accountMenuAnchorElement: undefined,
  store_setAccountMenuAnchorElement: (newValue: HTMLButtonElement | undefined) => { set(() => ({ store_accountMenuAnchorElement: newValue })); }, 

  store_feedbackWindowOpen: false,
  store_setFeedbackWindowOpen: (newValue: boolean) => { set(() => ({ store_feedbackWindowOpen: newValue })); }, 

  store_appSettingsOpen: false,
  store_setAppSettingsOpen: (newValue: boolean) => { set(() => ({ store_appSettingsOpen: newValue })); }, 
  store_accountSettingsOpen: false,
  store_setAccountSettingsOpen: (newValue: boolean) => { set(() => ({ store_accountSettingsOpen: newValue })); }, 
  store_showTermsOfServiceWindow: false,
  store_setShowTermsOfServiceWindow: (newValue: boolean) => { set(() => ({ store_showTermsOfServiceWindow: newValue })); }, 

  // RESET
  // ----------------------------------------------------------------------------------------
  store_reset: () => { set(() => (
  { 
    store_projectList: [],
    store_projectListIsLoading: false,
    store_projectUIMode: 'default',
    store_project: null,
    store_projectIsLoading: false,
    store_projectSurpressSaving: false,
    store_orgUsersLoading: false,
    store_projectSharingOrgs: [],
  
    store_aoiList: [],
    store_aoiListIsLoading: false,
    store_aoiUIMode: 'default',
    store_aoi: null,
    store_aoiIsLoading: false,
    store_aoiIsSaving: false,
    store_aoiIsDeleting: false,
    store_aoiFileIsUploading: false,
    store_aoiIsExporting: false,

    store_portfolioMapAois: [],
    store_aoiGroupProperties: undefined,
    store_editAoiGroupProperties: false,
    store_aoiGroupPropertiesIsSaving: false,
    store_editAoiAttributes: false,
  
    store_bundleList: [],
    store_bundleListIsLoading: false,
    store_bundle: null,
    store_bundleUIMode: 'default',
    store_bundleIsLoading: false,
    store_nrrs: [],
    store_nrrImpacts: [],

    store_hbvScenario: null,
    store_hbvIsRunning: false,
    store_hbvRunInstanceID: null,
    store_hbvScenarioIsLoading: false,
    store_hbvScenarioCompareIsLoading: false,
    store_hbvComparedScenarios: [],
    store_hbvScenarioIsSaving: false,
    store_hbvScenariRefreshIsLoading: false,
    store_chartColors: [],

    // store_map: null,
    // store_mapSearchControl: null,
    // store_mapDrawControl: null,
    // store_mapScaleControl: null,
    // store_mapCompassControl: null,
    store_baseMap: BaseMap.SatelliteStreets,
    store_threeDTerrain: false,

    store_layers: [],
    store_activeInfoLayer: null,
    store_layerLibraryActive: false,
    store_creatingNewLayer: false,
    store_deletingLayer: false,

    store_editLayer: undefined,
    store_uploadingLegendImage: false,
    store_uploadingMapImage: false,

    store_selectedParcels: [],
    store_selectedParcelsTotalAcres: undefined,
    store_parcelEditMode: false,
    store_parcelExtraAttributeList: [],
    store_parcelSearchIsRunning: false,
    store_parcelSearchEnabled: false,
    store_parcelSearchRunInstanceID: undefined,
    store_parcelSearchMaxResults: PARCEL_SEARCH_DEFAULT_MAX_RESULTS,
    store_parcelSearchExpression: { clauses:[], operator: 'and', states: [], attributes: [] },
    store_parcelSearchDbSortAttribName: undefined,
    store_parcelSearchDbSortOrderDesc: false,
    store_parcelSearchStates: [],
    store_parcelSearchAreaFilter: 'None',
    store_parcelSearchResultsViewerMode: false,
    store_parcelSearchAOIBufferMiles: "0",
    store_parcelTableViewer: false,
    //store_parcelTableViewerVertScroll: 0,
    store_parcelTableLocalSortAttribName: undefined,
    store_parcelTableLocalSortOrder: undefined,
    store_parcelsTableCurrPage: 0,
    store_parcelAttribSelector: false,

    store_isLoggedIn: false,
    store_firstName: '',
    store_lastName: '',
    store_idToken: '',
    store_accessToken: '',
    store_refreshToken: '',
    store_username: '',
    store_logInDate: null,
    store_forcedLogout: false,

    store_userProfile: null,
    store_userProfileIsDirty: false,
    store_userInfo: null,
    store_pwdResetRunningStep1: false,
    store_pwdResetRunningStep2: false,

    store_permCreateLayerLibraryGroup: false,
    store_permEditMainLayer: false,
  
    store_identify: undefined,
    store_lastIdentifySelectionID: undefined,
    store_identifySettingsOpen: false,

    store_drawerOpen: true,
    store_toastNotification: null,
    store_forcedAppReload: false,

    store_APIDebugMode: false,
    store_APIDebugData: [],

    store_baseMapPanelAnchorElement: undefined,
    store_layersPanelAnchorElement: undefined,
    store_accountMenuAnchorElement: undefined,

    store_feedbackWindowOpen: false,
    store_appSettingsOpen: false,
    store_accountSettingsOpen: false,
    store_showTermsOfServiceWindow: false,
  
  })); },

}));


export default useStore;








// Show the store's contents in React devtools
if (process.env.NODE_ENV === 'development') 
{
  mountStoreDevtool('Store', useStore);
}

