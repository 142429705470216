// HBV component

import useStore from "../store";
import { Box, Button, Stack, Typography } from "@mui/material";
import { theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import MyCircularProgress from "../Components/CircularProgress";
import { useState } from "react";
import { RunHBV, ResetHbv, SaveHbvResultsToScenario } from "./HbvOps";
import RoundedInputBox from "../Components/RoundedInputBox";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Bundles from "../Bundles/Bundles";
import { AOI_PORTFOLIO_MAP_AOI_ID } from "../Aois/Aois";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface HbvProps 
{
}

//-------------------------------------------------------------------------------
// HBV component
//-------------------------------------------------------------------------------
const Hbv = (props: HbvProps) => 
{
  // Get needed state data from the store
  const {  store_isLoggedIn, store_hbvIsRunning, store_aoi, store_hbvScenario, 
           store_setHbvIsRunning, store_setHbvRunInstanceID, 
           store_hbvScenarioIsLoading, store_hbvScenarioCompareIsLoading, 
           store_hbvScenarioIsSaving, store_hbvScenarioRefreshIsLoading, 
        } = useStore();

  const [newHbvName, setNewHbvName] = useState<string>('');
  
  
 



  //-------------------------------------------------------------------------------
  // Calculate HBV for the active AOI.
  //-------------------------------------------------------------------------------
  const onClickCalculateHbvButton = async () => 
  {
    ResetHbv();
    setNewHbvName('');

    RunHBV(store_aoi);
  }

  //-------------------------------------------------------------------------------
  // Cancel HBV
  //-------------------------------------------------------------------------------
  const onCancelHbv = async () => 
  {
    // Clear out the HBV run instance ID saved in the state store.
    store_setHbvRunInstanceID(null);

    // Tell the UI we are no longer running HBV
    store_setHbvIsRunning(false);
  }

  //-------------------------------------------------------------------------------
  // Save HBV
  //-------------------------------------------------------------------------------
  const onHbvSave = async () => 
  {
    if(!store_hbvScenario) return;

    SaveHbvResultsToScenario(newHbvName, store_hbvScenario);
  }
 
  //-------------------------------------------------------------------------------
  // Closes the current HBV results without saving.
  //-------------------------------------------------------------------------------
  const onHbvCloseWithoutSaving = () => 
  {
    ResetHbv();
  }












  
  // ******************************************************************************************
  // Main render - a user is NOT logged in
  // ******************************************************************************************

  if(!store_isLoggedIn) 
  return (
    <Box sx={{ color: theme_textColorBlended }}>
      Please log in to see this section.
    </Box>
  )

  // ******************************************************************************************
  // Main render - disable the whole HBV section if the AOI is in one of the "Show all AOIs" modes.
  // ******************************************************************************************

  if(store_aoi?.aoi_id === AOI_PORTFOLIO_MAP_AOI_ID)
  return (
    <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_textColorBlended, textTransform: 'none' }}>
      The HBV feature is disabled while viewing the <b>AOI Portfolio Map</b>.
    </Typography>      
  )

  // ******************************************************************************************
  // Main render - the HBV is currently being generated
  // ******************************************************************************************

  if(store_hbvIsRunning)
  return (

    <Stack>

      <Stack direction='row' sx={{ alignItems: 'center' }}>

        <MyCircularProgress />

        <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_orange, ml: 2, textTransform: 'capitalize' }}>
          Calculating...
        </Typography>

        <Button size="small" variant="outlined" onClick={onCancelHbv} sx={{ textTransform: 'none', height: '30px', ml: 1 }}>
          Cancel
        </Button>

      </Stack>

      <Typography sx={{ fontSize: '0.8rem', color: theme_textColorMain, mb: 1, textTransform: 'none', 
                        opacity: 0.7, mt: 1 }}>
        This operation can take several minutes.
      </Typography>

    </Stack>
  )

  // ******************************************************************************************
  // Main render - a saved HBV scenario is being loaded
  // ******************************************************************************************

  if(store_hbvScenarioIsLoading)
  return (

    <Stack direction='row' sx={{ alignItems: 'center' }}>

      <MyCircularProgress />

      <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_textColorBlended, ml: 2, textTransform: 'none' }}>
        Loading HBV scenario...
      </Typography>

    </Stack>
  )

  // ******************************************************************************************
  // Main render - an HBV scenario comparison is being loaded
  // ******************************************************************************************

  if(store_hbvScenarioCompareIsLoading)
  return (

    <Stack direction='row' sx={{ alignItems: 'center' }}>

      <MyCircularProgress />

      <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_textColorBlended, ml: 2, textTransform: 'none' }}>
        Comparing HBV scenarios...
      </Typography>

    </Stack>
  )

  // ******************************************************************************************
  // Main render - an HBV scenario is being saved
  // ******************************************************************************************

  if(store_hbvScenarioIsSaving)
  return (

    <Stack direction='row' sx={{ alignItems: 'center' }}>

      <MyCircularProgress />

      <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_textColorBlended, ml: 2, textTransform: 'none' }}>
        Saving HBV scenario...
      </Typography>

    </Stack>
  )

  // ******************************************************************************************
  // Main render - an HBV scenario is being re-run
  // ******************************************************************************************

  if(store_hbvScenarioRefreshIsLoading)
  return (

    <Stack direction='column'>

      <Stack direction='row' sx={{ alignItems: 'center' }}>
 
        <MyCircularProgress />

        <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_textColorBlended, ml: 2, textTransform: 'none' }}>
          Refreshing HBV scenario...
        </Typography>

      </Stack>

      <Typography sx={{ width: '100%', fontSize: '0.8rem', color: theme_textColorMain, mt: 2, textTransform: 'none', opacity: 0.7 }}>
        This operation can take several minutes.
      </Typography>

    </Stack>
  )

  // ******************************************************************************************
  // Main render - no active AOI
  // ******************************************************************************************

  if(!store_aoi)
  return (
    <Box sx={{ color: theme_textColorBlended }}>
      Please create or select an AOI.
    </Box>
  )

  // ******************************************************************************************
  // Main render - we have an active AOI
  // ******************************************************************************************

  return (

    <Stack sx={{ width: '100%', color: theme_textColorMain, alignItems: 'center' }}>

      {!store_hbvScenario || store_hbvScenario.scenario_id
        ?
          // No scenario, or a saved scenario - show NRR list and calc HBV button

          <Stack sx={{ width: '100%' }}>

            <Bundles/>

            <Button variant="outlined" onClick={onClickCalculateHbvButton} sx={{ borderRadius: 100, width: '85%', fontSize: '0.9rem', textTransform: 'none' }}>
              <AutoFixHighIcon sx={{ mr: 1.2 }}/>
              Calculate New HBV
            </Button>

          </Stack>
        :
          // Unsaved HBV scenario - show save/cancel UI only

          <Stack sx={{ width: '100%', alignItems: 'center' }}>

            <Typography sx={{ color: theme_textColorBlended, mb: 1 }}>
              Save this result as a scenario?
            </Typography>

            <RoundedInputBox title='New Scenario Name' onAccept={onHbvSave} buttonText='Save'
                            value={newHbvName} setValue={setNewHbvName} />

            <Button variant="text" onClick={onHbvCloseWithoutSaving} sx={{ color: theme_limeGreen, mt: 2, textTransform: 'none', fontSize: '1.1rem' }}>
              Close without saving
            </Button>

        </Stack>

      }

      {/* {store_hbvScenario && !store_hbvScenario.scenario_id
        ?
          <Stack sx={{ width: '100%', alignItems: 'center' }}>

            <Typography sx={{ color: theme_textColorBlended, mb: 1 }}>
              Save this result as a scenario?
            </Typography>

            <RoundedInputBox title='New Scenario Name' onAccept={onHbvSave} buttonText='Save'
                            value={newHbvName} setValue={setNewHbvName} />

            <Button variant="text" onClick={onHbvCloseWithoutSaving} sx={{ color: theme_limeGreen, mt: 2, textTransform: 'none', fontSize: '1.1rem' }}>
              Close without saving
            </Button>

          </Stack>
        :null
      } */}

    </Stack>
  )
}


export default Hbv;


