import { Link, Stack, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import DOMPurify from "dompurify";
import { theme_bgColorLight, theme_bgColorMain, theme_textColorBlended } from "../Theme";
import InfoIcon from '@mui/icons-material/Info';


interface AttributeHelpProps
{
  name: string;
  description?: string;
  units?: string;
  url?: string;
  iconSize?: string;
}

//-------------------------------------------------------------------------------
// Attribute help component.
//-------------------------------------------------------------------------------
const AttributeHelp = (props: AttributeHelpProps) => 
{








  // If the attribute has no info, take up the same amount of space, so things still line up nicely

  if(((!props.description || props.description.length === 0) && (!props.url || props.url.length === 0))) 
  return (
    // NOTE: May need to take into account the 'iconSize' optional prop
    <Stack sx={{ width: '12px' }}>
    </Stack>
  )

  // Main render

  return (

    <CustomTooltip title=
      {
        // Tooltip contents

        <Stack sx={{ p: '0px', borderRadius: 1, bgcolor: '#CDE2FD', maxWidth: '280px' }}>

        {/* Attribute name */}
  
        <Stack direction='row' sx={{ alignItems: 'top', justifyContent: 'space-between', mb: 1 }}>
          <Typography sx={{ color: theme_bgColorMain, opacity: 1, fontWeight: 'bold', fontSize: '1rem' }}>
            {props.name}
          </Typography>

          {/* Unit (only appears for some numerical attributes) */}

          {props.units
            ?
              <Typography sx={{ color: theme_bgColorLight, fontSize: '0.8rem', ml: 2, minWidth: '60px', textAlign: 'right' }}>
                  {props.units}
              </Typography>
            :null
          }

        </Stack>

        {/* Description text (optional; can contain HTML tags) */}
  
        {props.description && props.description.length > 0
          ?
            <Typography component='span' sx={{ color: theme_bgColorMain, opacity: 1, fontSize: '0.8rem', alignItems: 'center' }}>
              {/* NOTE: Even though this HTML comes from our own db, it is run through 
                        a sanitizer to strip it of all unsafe code/scripting just in case. */}
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.description)}} style={{whiteSpace: 'pre-line'}}/>
            </Typography>
          :null
        }
  
        {/* URL (optional) */}
  
        {props.url && props.url.length > 0
          ?
            <Link href={props.url} rel="noopener noreferrer" target="_blank" 
                  sx={{ mt: 1, fontSize: '0.9rem', color: '#255BF7', textAlign: 'right' }}>
              Extra info...
            </Link>
          :null
        }
  
      </Stack>
      }>

      {/* Mouse-over info icon */}

      <InfoIcon sx={{ fontSize: props.iconSize ? props.iconSize : '12px', color: theme_textColorBlended }}/>

    </CustomTooltip>
  )
}

export default AttributeHelp;


// Tooltip (customized(
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  
  <Tooltip arrow placement='left-start'

          // This moves the tooltip closer to the anchor icon (so it doesn't dissaper 
          // if the user moves the mouse over to the tooltip, for example to click a link)
          PopperProps={{
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  options: { offset: [-3, -3] }
                }
              ]
            }
          }}

          {...props} classes={{ popper: className }} />))(({ theme }) => (
{
  [`& .${tooltipClasses.arrow}`]: 
  {
      "&::before": 
      {
        backgroundColor: '#CDE2FD',
        //border: "1px solid #999"
      }
    },
  [`& .${tooltipClasses.tooltip}`]: 
  {
    backgroundColor: '#CDE2FD',
    maxWidth: 500,
  },
}));