// Vector attribute operations

import { FriendlyNumber, IsURL } from "./Globals";
import { ILayer, IVectorLayerAttribute } from "./Layers/LayerInterfaces";
import { Link, Stack, Typography } from "@mui/material";
import { GetLayerByID } from "./Layers/LayerOps";
import { GetParcelLayer } from "./Parcels/ParcelOps";
import { theme_orange } from "./Theme";





//-------------------------------------------------------------------------------
// Get the attribute info for the specified layer/attribute.
//-------------------------------------------------------------------------------
export function GetVectorLayerAttribute(layer: ILayer | undefined, attribute_name: string): IVectorLayerAttribute | undefined
{
  if(!layer) return undefined;

  // HBV layers have no attributes (currently) - send back a pre-determined attribute item so 
  // everything still works (like identify).
  
  // UPDATE: This has been fixed in the API - the below fix is no longer needed and will be removed

  // if(layer.isHBVLayer)
  // {
  //   const hbvAttribute: IVectorLayerAttribute = 
  //   {
  //     attribute_id: -1,
  //     attribute_name: attribute_name,
  //     display_name: attribute_name,
  //     data_type: "string",
  //     is_visible: true,
  //     is_range: false,
  //     settings: {}
  //   }
  //   return hbvAttribute;
  // }

  if(!layer.attributes || !attribute_name) return undefined;
  return layer.attributes.find(attrib => attrib.attribute_name === attribute_name);
}

//-------------------------------------------------------------------------------
// Get the attribute info for the specified layer/attribute.
//-------------------------------------------------------------------------------
export function GetVectorLayerAttribute2(layer_id: number, attribute_name: string): IVectorLayerAttribute | undefined
{
  if(!layer_id || !attribute_name) return undefined;

  const layer: ILayer | null = GetLayerByID(layer_id);
  if(!layer || !layer.attributes) return undefined;

  return GetVectorLayerAttribute(layer, attribute_name);
}

//-------------------------------------------------------------------------------
// Get the parcel layer attribute info for the specified attribute.
//-------------------------------------------------------------------------------
export function GetParcelLayerAttribute(attribute_name: string | undefined): IVectorLayerAttribute | undefined
{
  if(!attribute_name) return undefined;

  const layer: ILayer | undefined = GetParcelLayer();
  if(!layer || !layer.attributes) return undefined;

  return GetVectorLayerAttribute(layer, attribute_name);
}
