// Identify for an AOI.

import { Paper, Stack, Table, TableBody, tableCellClasses, TableContainer, TableHead } from "@mui/material";
import { theme_bgColorLight1, theme_bgColorMain } from "../Theme";
import useStore from "../store";
import { IAoi, IAoiAttribute } from "../Aois/AoiInterfaces";
import { IIdentifyAttribRowData } from "./IdentifyInterfaces";
import IdentifyTableRow from "./IdentifyTableRow";
import { GetAoiAttributeValueForAnAoi } from "../Aois/AoiOps";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface IdentifyAoiTableProps
{
  aoi?: IAoi;
}



//-------------------------------------------------------------------------------
// Identify table for an AOI.
//-------------------------------------------------------------------------------
const IdentifyAoiTable = (props: IdentifyAoiTableProps) => 
{
  // Get needed state data from the store
  const { store_aoiGroupProperties, store_project
  } = useStore();











  //-------------------------------------------------------------------------------
  // Returns a list of attribute rows ready for rendering.
  //-------------------------------------------------------------------------------
  function GetAllAttributeRows(): IIdentifyAttribRowData[]
  {
    if(!store_aoiGroupProperties || !props.aoi) return [];

    const outputArray: IIdentifyAttribRowData[] = [];

    // For each attribute, create a 'IIdentifyAttribRowData' item

    let nextID: number = 1;

    for(let i=0; i < store_aoiGroupProperties.attributes.length; i++)
    {
      const attrib: IAoiAttribute = store_aoiGroupProperties.attributes[i];

      // Ignore admin AOI attributes - they never get shown in identify
      if(attrib.is_admin) continue;

      let valueStr: string | undefined = GetAoiAttributeValueForAnAoi(attrib.id, props.aoi.aoi_id)?.value;

      // Option to skip attributes with empty values
      if(store_project && store_project.user_settings && store_project.user_settings.identify_showEmptyValues === false && 
         (valueStr === undefined || valueStr.length === 0))
        continue;
      else if(valueStr === undefined)
        valueStr = '';  // We'll still show the attribute, but with an empty value

      const newItem: IIdentifyAttribRowData = 
      {
        id: nextID++,
        attribName: attrib.name,
        attribValue: valueStr,
        units: attrib.units,
        description: attrib.description,
        descriptionLink: undefined
      }

      outputArray.push(newItem);
    }

    return outputArray;
  }






  // Return nothing if we have no layer or feature
  if(!props.aoi) return null;

  // Main render

  return (

    <Stack sx={{ maxHeight: '275px', overflow: 'auto' }}>

      <TableContainer component={Paper} sx={{ mt: 0.5, bgcolor: theme_bgColorMain }} elevation={0}>

        <Table size="small" aria-label="identify table" sx={{ [`& .${tableCellClasses.root}`]: {borderBottom: "1", borderColor: theme_bgColorLight1 + '8F'} }}>

          <TableHead>
          </TableHead>

          <TableBody>

            {GetAllAttributeRows().map(attribRowData => 
              <IdentifyTableRow key={attribRowData.id} attribRowData={attribRowData}/>
            )}

          </TableBody>

        </Table>
      </TableContainer>

    </Stack>
  )
}


export default IdentifyAoiTable;
