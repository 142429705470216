import { TableRow, TableCell, Stack, Typography } from "@mui/material";
import { theme_bgColorMain, theme_textColorBlended, theme_limeGreen } from "../Theme";
import { IIdentifyAttribRowData } from "./IdentifyInterfaces";
import { RenderIdentifyAttribRow } from "./IdentifyOps";
import AttributeHelp from "../Components/AttributeHelp";


interface IdentifyTableRowProps
{
  attribRowData: IIdentifyAttribRowData;
}

//-------------------------------------------------------------------------------
// Identify TableRow render component.
//-------------------------------------------------------------------------------
const IdentifyTableRow = (props: IdentifyTableRowProps) => 
{









  // Main render

  return (
    
    <TableRow key={props.attribRowData.attribName} sx={{ paddingTop: '0px', paddingBottom: '0px' }}>

      <TableCell sx={{ bgcolor: theme_bgColorMain, p: 0, pl: 0.4, pr: 0.4 }}>
        <Stack direction='row' sx={{ alignItems: 'center' }}>
          <AttributeHelp name={props.attribRowData.attribName} 
                         description={props.attribRowData.description}
                         units={props.attribRowData.units}
                         url={props.attribRowData.descriptionLink}/>
          <Typography sx={{ ml: '3px', color: theme_textColorBlended, fontSize: '0.7rem' }}>
            {props.attribRowData.attribName}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell sx={{ bgcolor: theme_bgColorMain, color: theme_limeGreen, fontSize: '0.7rem', p: 0, pl: 0.2, pr: 0.2 }}>
        {RenderIdentifyAttribRow(props.attribRowData)}
      </TableCell>

    </TableRow>
  )
}

export default IdentifyTableRow;
